(
<template>
  <page-data-section :progress="progressActive">
    <div class="employees-grid__wrapper">
      <header class="employees-grid__header">
        <div class="employees-grid__header--left">
          <breadcrumbs :breadcrumbs="breadcrumbs" />
          <h1 class="employees-grid__title">
            {{ $gettext("Enterprise employees") }}
          </h1>
          <p class="employees-grid__description"
             v-html="resultsParagraph"></p>
        </div>
        <button
          class="sk-btn sk-btn--white employees-grid__header--right"
          @click="openAddModal">
          <span class="employees-grid__icon employees-grid__icon--add"></span>
          {{ $gettext("Add employee") }}
        </button>
      </header>

      <spreadsheet
        :props="{
          columns,
          globalTranslations,
          data: employees,
          onRowSubmit: submitEmployee,
          pagination: {
            currentPage: Number(page),
            totalPages: Number(pages),
            onPageChange: (page) => {
              $router.push({ query: { ...$route.query, page } });
            },
            nextLabel: $gettext('Next'),
            previousLabel: $gettext('Previous'),
          },
        }" />
    </div>
  </page-data-section>
</template>
)

<script>
  import {mapGetters, mapState, mapMutations, mapActions} from 'vuex';
  import PageDataSection from '@/components/shared_components/page_data/PageDataSection';
  import {Spreadsheet, wrapReactComponent} from '@skiwo/react-spreadsheet';
  import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs';

  const getQueryParams = (route) => {
    const params = {
      'page[number]': route.query.page || 1,
      'page[size]': 25,
    };

    const flatFilterMapping = {
      firstName: 'filter[first_name]',
      lastName: 'filter[last_name]',
      email: 'filter[email]',
      phoneNumber: 'filter[full_phone_number]',
      bookingRef: 'filter[default_booking_reference]',
      orderRef: 'filter[default_payment_booking_reference]',
    };

    Object.entries(flatFilterMapping).forEach(([queryParam, apiParam]) => {
      if (route.query[queryParam]) {
        params[apiParam] = route.query[queryParam];
      }
    });

    const arrayFilterMapping = {
      departments: 'filter[department_ids][]',
      roles: 'filter[roles][]',
    };

    Object.entries(arrayFilterMapping).forEach(([queryParam, apiParam]) => {
      if (route.query[queryParam]) {
        route.query[queryParam].split(',').forEach((value) => {
          params[apiParam] = value;
        });
      }
    });

    return params;
  };

  export default {
    asyncData({store, route}) {
      let promiseArr = '';
      const params = getQueryParams(route);

      if (!store.state.EnterpriseStore.enterpriseEmployees) {
        promiseArr = [
          ...promiseArr,
          store.dispatch('EnterpriseStore/searchEmployees', params),
        ];
      }

      if (!store.state.EnterpriseStore.departments) {
        promiseArr = [
          ...promiseArr,
          store.dispatch('EnterpriseStore/getDepartments', {verbose: true}),
        ];
      }

      return promiseArr ? Promise.all(promiseArr) : promiseArr;
    },
    components: {
      'page-data-section': PageDataSection,
      spreadsheet: wrapReactComponent(Spreadsheet),
      breadcrumbs: Breadcrumbs,
    },
    data() {
      return {
        breadcrumbs: [
          {
            label: 'Employees',
            link: this.$route.path,
          },
        ],
        nameSearch: this.$route.query.name || '',
        departmentId: this.$route.query.department || '',
        progressActive: false,
        roles: [
          {label: this.$gettext('Admin'), value: 'admin'},
          {label: this.$gettext('Manager'), value: 'finance'},
          {label: this.$gettext('Coordinator'), value: 'booker'},
        ],
        globalTranslations: {
          required: this.$gettext('This field is required'),
          email: this.$gettext('This is not a valid email address'),
          modalTitle: this.$gettext('Unsaved Changes'),
          modalMessage: this.$gettext('You have unsaved changes on this page. If you leave, your changes will be lost.'),
          modalKeepEditingLabel: this.$gettext('Keep editing'),
          modalDiscardChangesLabel: this.$gettext('Discard changes'),
          modalSaveChangesLabel: this.$gettext('Save changes'),
          modalSaveChangesLoadingLabel: this.$gettext('Saving...'),
        },
        saveLabel: this.$gettext('Save'),
        savingLabel: this.$gettext('Saving...'),
        resetLabel: this.$gettext('Reset'),
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userUid',
        'userIsAdminOfEnterprise',
        'userIsFinanceManager',
        'userIsManager',
        'enterpriseId',
      ]),
      ...mapState('EnterpriseStore', {
        departments: (state) => state.departments.map((department) => ({
          label: department.name,
          value: department.id,
        })) || [],
        employees(state) {
          if (!state.enterpriseEmployees) return [];

          const phoneCodeOptions = this.$helperData.getSelectCountryCodesLong(this);

          return (
            state.enterpriseEmployees.map((employee) => ({
              id: {type: 'text', text: String(employee.id), nonEditable: true},
              firstName: {
                type: 'text',
                text: employee.firstName,
                required: true,
              },
              lastName: {type: 'text', text: employee.lastName, required: true},
              email: {type: 'email', text: employee.email, required: true},
              phoneCode: {
                type: 'select',
                options: phoneCodeOptions.map(({id}) => ({
                  label: id,
                  value: id,
                })),
                selected: [
                  {
                    label: employee.phoneCode,
                    value: employee.phoneCode,
                  },
                ],
                groupId: 'phone',
              },
              phoneNumber: {
                type: 'text',
                text: employee.phoneNumber || '',
                groupId: 'phone',
              },
              departments: {
                type: 'select',
                multiple: true,
                options: this.departments,
                selectedLabel: this.getSelectedLabel,
                selected:
                  employee.departments.map((department) => ({
                    label: department.name,
                    value: department.id,
                  })) || [],
              },
              bookingReference: {
                type: 'text',
                text: employee.bookingReference || '',
              },
              paymentBookingReference: {
                type: 'text',
                text: employee.paymentBookingReference || '',
              },
              roles: {
                type: 'select',
                multiple: true,
                options: this.roles,
                selectedLabel: this.getSelectedLabel,
                selected: this.getFilteredRoles(employee.roles),
              },
              submission: {
                type: 'actions',
                saveLabel: this.saveLabel,
                savingLabel: this.savingLabel,
                resetLabel: this.resetLabel,
              },
            })) || []
          );
        },
        pages: ({employeesPages}) => employeesPages || 1,
        page: ({employeesPage}) => employeesPage || 1,
        totalEmployeesCount: ({employeesCount}) => employeesCount || 0,
      }),
      resultsParagraph() {
        const template = this.$gettext(
          'Showing <span>%{count}</span> results of <span>%{total}</span>'
        );
        return this.$gettextInterpolate(template, {
          count: this.employees.length,
          total: this.totalEmployeesCount,
        });
      },
      columns() {
        return [
          {
            columnId: 'id',
            title: this.$gettext('ID'),
            width: 64,
          },
          {
            columnId: 'firstName',
            title: this.$gettext('First Name'),
            width: 145,
            filterType: 'text',
            placeholder: this.$gettext('Search...'),
            text: this.$route.query.firstName || '',
            onSearch: (value) => {
              this.setRouteFilters('firstName', value);
            },
          },
          {
            columnId: 'lastName',
            title: this.$gettext('Last Name'),
            width: 145,
            filterType: 'text',
            placeholder: this.$gettext('Search...'),
            text: this.$route.query.lastName || '',
            onSearch: (value) => {
              this.setRouteFilters('lastName', value);
            },
          },
          {
            columnId: 'email',
            title: this.$gettext('Email'),
            width: 200,
            filterType: 'text',
            placeholder: this.$gettext('Search...'),
            text: this.$route.query.email || '',
            onSearch: (value) => {
              this.setRouteFilters('email', value);
            },
          },
          {
            columnId: 'phoneCode',
            title: this.$gettext('Code'),
            width: 64,
            colspan: 2,
            filterType: 'text',
            placeholder: this.$gettext('Search...'),
            text: this.$route.query.phoneNumber || '',
            onSearch: (value) => {
              this.setRouteFilters('phoneNumber', value);
            },
          },
          {columnId: 'phoneNumber', title: this.$gettext('Phone'), width: 96},
          {
            columnId: 'departments',
            title: this.$gettext('Departments'),
            width: 200,
            filterType: 'select',
            placeholder: this.$gettext('Search...'),
            options: this.departments,
            multiple: true,
            selected: this.querySelectedDepartments,
            selectedLabel: this.getSelectedLabel,
            onSearch: (value) => {
              this.setRouteFilters(
                'departments',
                this.getJoinedSelectionValues(value)
              );
            },
          },
          {
            columnId: 'bookingReference',
            title: this.$gettext('Buyer Ref'),
            width: 96,
            filterType: 'text',
            placeholder: this.$gettext('Search...'),
            text: this.$route.query.bookingRef || '',
            onSearch: (value) => {
              this.setRouteFilters('bookingRef', value);
            },
          },
          {
            columnId: 'paymentBookingReference',
            title: this.$gettext('Order Ref'),
            width: 96,
            filterType: 'text',
            placeholder: this.$gettext('Search...'),
            text: this.$route.query.orderRef || '',
            onSearch: (value) => {
              this.setRouteFilters('orderRef', value);
            },
          },
          {
            columnId: 'roles',
            title: this.$gettext('Roles'),
            width: 120,
            filterType: 'select',
            placeholder: this.$gettext('Search...'),
            options: this.roles,
            multiple: true,
            selected: this.querySelectedRoles,
            selectedLabel: this.getSelectedLabel,
            onSearch: (value) => {
              this.setRouteFilters('roles', this.getJoinedSelectionValues(value));
            },
          },
          {
            columnId: 'submission',
            type: 'actions',
            resetLabel: this.$gettext('Reset all'),
            saveLabel: this.$gettext('Save all'),
            width: 164,
          },
        ];
      },
      querySelectedDepartments() {
        return (
          this.departments.filter((department) => this.$route.query.departments
            ?.split(',')
            .includes(department.value.toString())) || []
        );
      },
      querySelectedRoles() {
        return (
          this.roles.filter((role) => this.$route.query.roles?.split(',').includes(role.value.toString())) || []
        );
      },
    },
    watch: {
      $route() {
        this.refetchData();
      },
    },
    methods: {
      ...mapActions('EnterpriseStore', ['updateEmployee']),
      ...mapMutations('EnterpriseStore', ['removeEmployees']),
      openAddModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'add-employee',
          width: 410,
          data: {
            title: this.$gettext('New employee'),
            context: this,
            successHandlingCallbackName: 'refetchData',
          },
        });
      },
      refetchData() {
        // Do we need duplicate call of removeEmployees mutation?
        this.removeEmployees();
        this.$store.commit('EnterpriseStore/removeEmployees');
        this.$store.commit('GlobalStore/startProgress');
        this.$options
          .asyncData({
            store: this.$store,
            route: this.$route,
          })
          .then(() => {
            this.$store.commit('GlobalStore/stopProgress');
          });
      },
      async submitEmployee(updatedData, rowId) {
        const form = new FormData();
        let params = form;

        const uid = this.$store.state.EnterpriseStore.enterpriseEmployees[rowId].uid;

        if (uid) {
          params = {
            form,
            id: uid,
            skipSetEmployee: true,
          };

          form.append('employee[uid]', uid);

          if (this.userIsManager && this.enterpriseId) {
            params.enterpriseId = this.enterpriseId;
          }
        }

        form.append('employee[firstName]', updatedData.firstName.text.trim());

        form.append('employee[lastName]', updatedData.lastName.text.trim());

        if (updatedData.phoneCode.selected[0].value || updatedData.phoneNumber.text) {
          form.append('employee[phoneCode]', updatedData.phoneCode.selected[0].value);
          form.append('employee[phoneNumber]', updatedData.phoneNumber.text);
        }

        for (const dep of updatedData.departments.selected) {
          form.append('employee[departments][][id]', dep.value);
        }

        form.append(
          'employee[defaultBookingReference]',
          updatedData.bookingReference.text.trim()
        );

        form.append(
          'employee[defaultPaymentBookingReference]',
          updatedData.paymentBookingReference.text.trim()
        );

        form.append(
          'employee[email]',
          updatedData.email.text.toLowerCase().trim()
        );

        for (const role of updatedData.roles.selected) {
          form.append('employee[roles][]', role.value);

          if (role.value === 'booker') {
            form.append('employee[roles][]', 'organizer');
          }
        }

        let success = false;
        let fieldErrors;

        this.$emit('startprogress');

        try {
          await this.updateEmployee(params);
          success = true;
        } catch (error) {
          success = false;

          if (error && error?.data?.errors) {
            fieldErrors = Object.fromEntries(
              error.data.errors.flatMap((obj) => Object.entries(obj).map(([key, value]) => {
                const message = value.join(', ');

                if (key === 'defaultBookingReference') {
                  return ['bookingReference', message];
                }

                if (key === 'defaultPaymentBookingReference') {
                  return ['paymentBookingReference', message];
                }

                return [key, message];
              }))
            );
          }
        }

        this.$emit('stopprogress');

        return {
          success,
          fieldErrors,
        };
      },
      getFilteredRoles(roles) {
        return (
          roles?.reduce((acc, curr) => {
            const role = this.roles.find((role) => role.value === curr);
            if (role) {
              acc.push(role);
            }
            return acc;
          }, []) || []
        );
      },
      setRouteFilters(name, value) {
        const routeQuery = {...this.$route.query, [name]: value};

        if (!value) {
          delete routeQuery[name];
        }

        this.$router.push({query: routeQuery});
      },
      getJoinedSelectionValues(value = []) {
        return value?.reduce((acc, curr) => {
          if (acc) {
            acc += ',';
          }
          acc += curr.value;
          return acc;
        }, '');
      },
      getSelectedLabel(value) {
        if (!value) return '';

        const template = this.$gettext('%{count} selected');
        return this.$gettextInterpolate(template, {count: value});
      },

    },
    beforeRouteLeave(to, from, next) {
      this.removeEmployees();
      next();
    },
  };
</script>

<style scoped>
@import "~@skiwo/react-spreadsheet/styles.css";

.employees-grid__wrapper {
  margin: 0;
  padding: 24px 16px 100px;
  background-color: #fff;
}

.employees-grid__header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 32px;
}

.employees-grid__header--left {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;
}

.employees-grid__header--right {
  display: flex;
  flex-grow: 0;
  gap: 8px;
  align-items: center;
  width: max-content;
  padding: 8px 12px;
  border-color: #98a2b3;
}

.employees-grid__icon {
  display: inline-flex;
  width: 16px;
  height: 16px;
  background-position: center;
  background-size: contain;
}

.employees-grid__icon--add {
  background-image: var(--plus-icon);
}

.employees-grid__title {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

.employees-grid__description {
  color: #667085;
  font-size: 14px;
  line-height: 20px;
}

.employees-grid__description >>> span {
  color: #344054;
}
</style>

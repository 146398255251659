(
<template>
  <div class="breadcrumbs">
    <router-link v-for="breadcrumb in internalBreadcrumbs"
                 :key="breadcrumb.label"
                 class="breadcrumb"
                 :to="breadcrumb.link">
      {{ breadcrumb.label }}
    </router-link>
  </div>
</template>
)

<script>
  export default {
    props: {
      breadcrumbs: {
        type: Array,
        required: true,
      },
    },
    computed: {
      internalBreadcrumbs() {
        return [
          {
            label: 'SKIWO AS',
            link: '/',
          },
          ...this.breadcrumbs,
        ];
      },
    },
  };
</script>

<style scoped>
  .breadcrumbs {
    display: flex;
    flex-direction: row;
    gap: 4px;
  }

  .breadcrumb {
    display: flex;
    gap: 4px;
    align-items: center;
    color: #333;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-decoration: none;
  }

  .breadcrumb:not(:last-child)::after {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    background-image: url(~@assets/imgs/arrows/arrow_to_right.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
</style>

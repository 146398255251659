// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/arrows/arrow_to_right.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.breadcrumbs[data-v-6fc5c64c] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: horizontal;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: row;\n          flex-direction: row;\n  gap: 4px;\n}\n.breadcrumb[data-v-6fc5c64c] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  gap: 4px;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  color: #333;\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 16px;\n  text-decoration: none;\n}\n.breadcrumb[data-v-6fc5c64c]:not(:last-child)::after {\n  content: '';\n  display: block;\n  width: 12px;\n  height: 12px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: center;\n  background-size: contain;\n  background-repeat: no-repeat;\n}\n", ""]);
// Exports
module.exports = exports;

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-data-section',{attrs:{"progress":_vm.progressActive}},[_c('div',{staticClass:"employees-grid__wrapper"},[_c('header',{staticClass:"employees-grid__header"},[_c('div',{staticClass:"employees-grid__header--left"},[_c('breadcrumbs',{attrs:{"breadcrumbs":_vm.breadcrumbs}}),_vm._v(" "),_c('h1',{staticClass:"employees-grid__title"},[_vm._v("\n          "+_vm._s(_vm.$gettext("Enterprise employees"))+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"employees-grid__description",domProps:{"innerHTML":_vm._s(_vm.resultsParagraph)}})],1),_vm._v(" "),_c('button',{staticClass:"sk-btn sk-btn--white employees-grid__header--right",on:{"click":_vm.openAddModal}},[_c('span',{staticClass:"employees-grid__icon employees-grid__icon--add"}),_vm._v("\n        "+_vm._s(_vm.$gettext("Add employee"))+"\n      ")])]),_vm._v(" "),_c('spreadsheet',{attrs:{"props":{
        columns: _vm.columns,
        globalTranslations: _vm.globalTranslations,
        data: _vm.employees,
        onRowSubmit: _vm.submitEmployee,
        pagination: {
          currentPage: Number(_vm.page),
          totalPages: Number(_vm.pages),
          onPageChange: function (page) {
            _vm.$router.push({ query: Object.assign({}, _vm.$route.query, {page: page}) });
          },
          nextLabel: _vm.$gettext('Next'),
          previousLabel: _vm.$gettext('Previous'),
        },
      }}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }